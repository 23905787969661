.ide-editor {
  margin-bottom: 20px;
}

.ide-input,
.ide-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.ide-actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.ide-button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #347ab4;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.ide-button:hover {
  background-color: #0056b3;
}

.ide-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}

.ide-input::selection {
  background-color: #007bff;
  color: #fff;
}

.header-icon {
  margin-right: auto;
  height: 50px;
}

.header-icon img {
  max-height: 100%;
  width: auto;
}

.header {
  width: 100%;
  background-color: #3d3e41;
  padding: 20px;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: space-between; /* Adjust alignment as needed */
  align-items: center;
  padding: 10px 20px; /* Optional: Add padding for space around the items */
  background-color: #3d3e41; /* Set background color */
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  gap: 20px; /* Adjust the gap between items */
}

.nav-item {
  /* Optional: Set specific width or use flex: 1 to distribute items evenly */
  /* width: 100px; */
  /* flex: 1; */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: text-decoration 0.3s ease; /* Add smooth underline transition */
}

.nav-link:hover {
  text-decoration: underline;
}


.video-player {
  max-width: 100%; /* Set a maximum width for the video player */
  width: 100%; /* Ensure the video player takes up 100% of its parent's width */
  margin: 0 auto; /* Center the video player horizontally */
}

.video {
  width: 100%; /* Ensure the video takes up 100% of its parent's width */
  height: auto; /* Maintain aspect ratio */
}


.header {
  display: flex;
  justify-content: flex-end;
}

.header-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.header-links li {
  margin-left: 10px;
}

.header-links li:first-child {
  margin-left: 0;
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-group {
  margin-bottom: 10px;
}

.label {
  display: block;
  font-weight: bold;
}

.form-input {
  width: 70%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.form-button {
  padding: 5px 10px;
  background-color: #347ab4;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.lesson-form {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-input,
.form-select {
  padding: 0.5rem;
}

.submit-button {
  padding: 0.5rem 1rem;
  background-color: #347ab4;
  color: white;
  border: none;
}

.cms-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px; /* Adjust the gap between components as needed */
  max-width: 90%;
  margin: 0 auto;
}

.cms-grid-item {
  width: 100%; /* Ensure each item takes full width of its grid cell */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
}

.share-button {
  margin-top: 10px;
}

.topics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.category-item {
  /* Add styling for each category item */
}

.lesson-component-container {
  max-width: 100%;
  overflow-y: auto;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.lesson-text {
  white-space: pre-wrap;
  font-family: 'Arial', Courier, monospace;
  font-weight: bold;
  /*line-height: 12px;*/
}

.lesson-text code {
  background-color: #f2f2f2; /* Light gray background color for code blocks */
  padding: 4px 8px; /* Adjust padding based on your preference */
  border-radius: 4px; /* Optional: Add rounded corners to code blocks */
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  white-space: pre-wrap;
}


.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Adjust as needed */
  gap: 20px; /* Adjust the gap between items */
}

.grid-item-left,
.grid-item-right {
  width: calc(50% - 10px); /* Adjust the width and gap */
  /* Add other styles for grid items if necessary */
}

@media only screen and (max-width: 767px) {
  .grid-item-left,
  .grid-item-right {
    width: 100%;
  }
}


.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: left;
  align-items: center;
  text-align: left;
  color: #3d3e41;
  font-size: 2rem;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  margin: 0 auto;
  /*padding-left: 20px;*/
}

.hero-image {
  margin-top: 50px;
  width: 100%;
  height: auto;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}

@media (max-width: 768px) {
  .hero {
    grid-template-columns: 1fr;
  }
}

.hero-description {
  max-width: 80%;
}

.cta {
  display: flex;
  flex-direction: column;
  background-color: #347ab4;
  width: 100%;
  height: 200px;
  margin-top: 75px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  color: #dddddd;
  font-size: 2.5rem;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}



@media (max-width: 768px) {
  .cta {
    display: none;
  }
}

.features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2rem 0;
  /*padding-top: 50px;*/
}

.feature {
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 80%;
  margin: auto; /* Center horizontally */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow */
  border-radius: 4px;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.5rem;
  color: #3d3e41;
}

.feature p {
  font-size: 1rem;
  color: #3d3e41;
}

.feature-description {
  margin-top: 10px;
  margin-bottom: 10px;
}

.feature-img-left {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.feature-img-right {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.profile-container {
  display: flex;
  /*flex-direction: column;*/
  align-items: flex-start;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-right: 20px;
}


.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  grid-gap: 5px;
  background-color: #ddd;
  border-radius: 4px;
  padding: 15px;
}

.profile-grid-item {
  height: 50px;
  border: 1px solid #3d3e41;
  cursor: pointer;
  border-radius: 4px;
}

.completed-lesson {
  background-color: #4CAF50;
}

.incomplete-lesson {
  background-color: gray;
}

.profile-grid-item:hover::after {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #2a2b2e;
  color: white;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
}

.category-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3d3e41;
}

.topic-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #3d3e41;
}

ol.lessons-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lesson-tile {
  display: block;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #3d3e41;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.lesson-tile:hover {
  background-color: #f0f0f0;
}

.footer {
  width: 100%;
  background-color: #3d3e41;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: space-around;
}

.footer-section {
  flex: 1;
  padding: 0 20px;
}

.footer h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
}

/* Add this CSS to your stylesheets or index.css */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the container height to the viewport height */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-bar {
  width: 100%; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  background-color: #ddd; /* Default color for incomplete lessons */
  border-radius: 4px; /* Adjust as needed */
  overflow: hidden;
  margin-bottom: 50px;

}

.progress-bar-fill {
  height: 100%;
  background-color: #4CAF50; /* Color for completed lessons */
  transition: width 0.3s ease; /* Add a smooth transition effect */
}